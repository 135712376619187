body {
  background-color: white;
}

a {
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }

  &:active {
    color: inherit;
  }
}